import React, { useState } from 'react';

function Header() {
  return (
    <header>
<div class="container">
      <div class="row">
        <div class="col-sm">
          <center><img src="https://links.krh1.co.uk/images/logo.png" class="img-fluid d-block mb-3" alt="logo" width="250px"></img></center>
        </div>
        <div class="col-md">
          <p class="h1 text-black text-center pt-5">KRH1Designs <br></br>QR Generator</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="text-white pb-4 text-center">Feel free to create as many QR Codes as you desire but please remember to save them as they are not stored on the server!</p>
        </div>
      </div>
      </div>
    </header>
  );
}

export default Header;


















